import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import VerifyEmailCTA from "./pages/VerifyEmailCTA";

export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  if (currentUser && currentUser.emailVerified) {
    return children;
  } else if (currentUser && !currentUser.emailVerified) {
    return <VerifyEmailCTA />;
  }
  else {
    return <Navigate to="/login" />;
  }

}
