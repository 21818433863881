import React, { useEffect, useState } from "react";
import { enhanceText } from "../../services/enhanceTextService";
import EnhancedTextResult from "../subcomponents/dashboardComponents/EnhancedTextResult";
import Navbar from "./common/Navbar";
import EnhancerSubmitForm from "../subcomponents/dashboardComponents/EnhancerSubmitForm";
import { useAuth } from "../../contexts/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useUserData } from "../../contexts/UserDataContext";
import { useEnhancedTexts } from "../../contexts/EnhancedTextsContext";
import { motion, AnimatePresence } from 'framer-motion';




export default function Dashboard() {
  const [error, setError] = useState("");

  const [isEnhancing, setIsEnhancing] = useState(false);
  const [inputText, setInputText] = useState("");
  const [localEnhancedTexts, setLocalEnhancedTexts] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { currentUser } = useAuth();
  const { userData } = useUserData();
  const { enhancedTexts, deleteEnhancedText } = useEnhancedTexts();


  useEffect(() => {
    if (userData) {
      setIsEnhancing(userData.activeRequest);
    }
  }, [userData]);

  useEffect(() => {
    setLocalEnhancedTexts(enhancedTexts);
  }, [enhancedTexts]);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  const handleEnhancerSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setIsEnhancing(true);

    try {
      await enhanceText(inputText, currentUser);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setIsEnhancing(false);
    } finally {
      setInputText("");
    }
  };

  const handleResultCardDelete = async (id) => {
    await deleteEnhancedText(id);
  };

  const markForDeletion = (id) => {
    // Update state to mark the item for deletion
    setLocalEnhancedTexts(currentTexts =>
      currentTexts.map(text =>
        text.id === id ? { ...text, markedForDeletion: true } : text
      )
    );

    // Set a timeout to delete the item after the animation
    setTimeout(() => {
      handleResultCardDelete(id);
    }, 200); // Ensure this matches your exit animation duration
  };

  const cardMountUnmountVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20, transition: { duration: 0.2 } },
  };


  return (
    <div className="w-full flex flex-col min-h-screen">
      <div className="sticky w-full z-10 top-0 flex flex-col items-center dark:bg-gray-950 h-fit">
        <Navbar />
        <EnhancerSubmitForm
          isEnhancing={isEnhancing}
          handleEnhancerSubmit={handleEnhancerSubmit}
          inputText={inputText}
          setInputText={setInputText}
        />

        {error && (
          <div
            className="flex items-center w-full max-w-sm justify-between bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2"
            role="alert"
          >
            <span>{error}</span>
            <button type="button" onClick={() => setError("")}>
              <XMarkIcon className="w-6 hover:text-red-500" />
            </button>
          </div>
        )}
      </div>
      <div className="w-full flex justify-center pt-4 flex-grow overflow-y-auto" style={{ maxHeight: `calc(100vh - 225px)` }}>
        <div className="max-w-3xl w-full flex flex-col gap-4 px-2">
          <AnimatePresence initial={false}>
            {localEnhancedTexts.map((item) =>
              item.id && !item.markedForDeletion ? (
                <motion.div
                  key={item.id}
                  initial={isInitialRender ? "visible" : "hidden"}
                  animate="animate"
                  exit="exit"
                  variants={cardMountUnmountVariants}
                  layout // This prop is important for smooth repositioning of elements
                >
                  <EnhancedTextResult
                    key={item.id}
                    isLoading={item.isPending}
                    originalText={item.originalText}
                    enhancedText={item.enhancedText}
                    tokensUsed={item.tokensUsed}
                    timestamp={item.timestamp}
                    onDelete={() => markForDeletion(item.id)} // Mark item for deletion instead of direct deletion
                  />
                </motion.div>
              ) : null
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
