import React, { useEffect, useState, useRef } from 'react';
import { HiCog, HiCurrencyDollar, HiLogout } from 'react-icons/hi';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { IoMdInformationCircle } from "react-icons/io";
import { AnimatePresence, motion } from 'framer-motion';
import { useUserData } from '../../../contexts/UserDataContext';
import { Tooltip } from 'react-tooltip'
import "../../../styles/miniProfile.css";


function MiniProfile() {
  const { currentUser, logout } = useAuth();
  const { userData } = useUserData();
  const [miniName, setMiniName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    currentUser && setMiniName(currentUser.displayName || currentUser.email);
  }, [currentUser]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const dropdownVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const formatTokens = (tokens) => {
    if (tokens < 10000) {
      return tokens.toString();
    } else if (tokens < 1000000) {
      return (tokens / 1000).toFixed(0) + 'k';
    } else {
      return (tokens / 1000000).toFixed(1) + 'm';
    }
  };



  return (
    <>
      {userData ? (
        <div className="relative" ref={dropdownRef}>
          <div
            className=" items-center cursor-pointer standard-text"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <div className="text-sm hidden sm:block  rounded-md p-2 hover:bg-gray-200 dark:hover:bg-gray-800">
              <div className='flex gap-1'>
                <p >{miniName}</p>
                <ChevronDownIcon className="size-6" />
              </div>
            </div>

            <p className="text-sm block sm:hidden"><Bars3Icon className='size-7' /></p>

          </div>
          <AnimatePresence>
            {dropdownOpen && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={dropdownVariants}
                transition={{ duration: 0.2 }}
                className="absolute right-0 flex flex-col mt-2 w-56 bg-white dark:bg-gray-900 rounded-md shadow-xl standard-text"
              >
                <div className="px-4 py-2 flex flex-col text-sm truncate gap-2">
                  <p>{currentUser.email}</p>
                  <div className='flex justify-between items-center'>
                    <p className='flex items-center gap-1'> Remaining Tokens: {formatTokens(userData.remainingAPITokens)} </p>
                    <IoMdInformationCircle className='tokensInfoHover' />
                    <Tooltip anchorSelect=".tokensInfoHover" clickable>
                      <p>Your remaining monthly api tokens</p>
                      <Link to={"/faq"} className='text-blue-600  hover:underline'>Learn more</Link>
                    </Tooltip>

                  </div>
                </div>
                <div className="border-t border-gray-100 dark:border-gray-700" />
                <Link to="/update-profile" className="miniprofile-dropdown-section">
                  <HiCog className="inline mr-2" /> Account Settings
                </Link>
                <div className="block px-4 py-2 text-sm  cursor-not-allowed">
                  <HiCurrencyDollar className="inline mr-2" /> Manage Subscription
                </div>
                <div className="border-t border-gray-100 dark:border-gray-700" />
                <div
                  className="text-red-500 hover:text-red-600 rounded-b-lg miniprofile-dropdown-section"
                  onClick={logout}
                >
                  <HiLogout className="inline mr-2" />
                  <span className=''>Sign Out</span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <div className="flex gap-1 items-center cursor-pointer p-2 standard-text rounded-md hover:bg-gray-200 dark:hover:bg-gray-800">
          <Link to={"/login"} className="text-sm">Sign In</Link>
        </div>
      )}
    </>
  );
}

export default MiniProfile;
