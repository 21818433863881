import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import UpdateProfile from "./pages/UpdateProfile";
import PrivateRoute from "./PrivateRoute";
import { UserDataProvider } from "../contexts/UserDataContext";
import { EnhancedTextsProvider } from "../contexts/EnhancedTextsContext";
import FAQPage from "./pages/FAQ";
import AccountManagement from "./pages/accountmanagement/AccountManagement";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="min-h-screen min-w-full flex flex-col dark:bg-gray-950">
      <BrowserRouter>
        <AuthProvider>
          <UserDataProvider>

            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <EnhancedTextsProvider>
                      <Dashboard />
                    </EnhancedTextsProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/update-profile"
                element={
                  <PrivateRoute>
                    <UpdateProfile />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/verify-email"
                element={
                  <PrivateRoute />
                }
              ></Route>
              <Route
                path="/acctmgmt"
                element={
                  <AccountManagement />
                }
              ></Route>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

          </UserDataProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>

  );
}

export default App;
