import React, { useState } from "react";
import { XMarkIcon, ClipboardIcon, CheckIcon } from "@heroicons/react/24/outline";
import Accordion from "../../pages/common/Accordion";
import { AnimatePresence, motion } from "framer-motion";

function EnhancedTextResult({
  isLoading,
  originalText,
  enhancedText,
  tokensUsed,
  onDelete,
  timestamp,
}) {
  const [copySuccess, setCopySuccess] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(enhancedText).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      () => {
        setCopySuccess("Failed to copy!");
      }
    );
  };

  const deleteDialogVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
      <div className="border dark:border-2 dark:border-gray-900 rounded-lg relative overflow-hidden">
        {isLoading ? (
          <div className="flex justify-center items-center p-6 w-full h-fit">
            <span className="standard-text-lc font-light animate-pulse">
              Enhancing Text...
            </span>
          </div>
        ) : (
          <>
            <AnimatePresence>
              {deleteDialogOpen && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={deleteDialogVariants}
                  transition={{ duration: 0.1 }}
                  onClick={() => setDeleteDialogOpen(false)}
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center rounded-lg"
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="bg-white dark:bg-gray-800 p-4 rounded-lg"
                  >
                    <p className="standard-text">
                      Are you sure you want to delete this enhanced text?
                    </p>
                    <p className="standard-text">This cannot be reversed!</p>
                    <div className="flex justify-end gap-2 mt-2">
                      <button
                        onClick={() => setDeleteDialogOpen(false)}
                        className="standard-text px-2 py-1 rounded-lg bg-gray-300 hover:bg-gray-400 dark:bg-gray-600 dark:hover:bg-gray-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={onDelete}
                        className="text-white px-2 py-1 rounded-lg bg-red-700 hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="p-4 pt-2 standard-text">
              <div className="flex justify-between">
                <span className="text-sm standard-text-lc">
                  {timestamp} | Total Tokens: {tokensUsed}
                </span>
                <button
                  onClick={() => setDeleteDialogOpen(true)}
                  className="pb-2"
                >
                  <XMarkIcon className="size-6" />
                </button>
              </div>

              <div className="w-full ">
                <div className="flex justify-between items-center w-full p-2.5 rounded-t-lg bg-gray-200 dark:bg-gray-900 standard-text-lc text-xs">
                  <span className="font-semibold">
                    Enhanced Text
                  </span>

                  <div className="standard-text-lc" onClick={copyToClipboard}>
                    {copySuccess ? (
                      <div className="flex items-center">
                        <CheckIcon className="mr-1 h-4 w-4 " />
                        <p className="">{copySuccess}</p>
                      </div>
                    ) : (
                      <div className="flex items-center hover:cursor-pointer">
                        <ClipboardIcon className="h-4 w-4 mr-1" />
                        <span className="font-semibold">Copy text</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="preformatted-text border-x border-gray-200 dark:border-x-2 dark:border-gray-900 dark:bg-gray-950 px-2.5 py-2 overflow-scroll overflow-x-hidden max-h-72">
                  <p className="standard-text text-sm">{enhancedText}</p>
                </div>

                <Accordion headerText="Original Text">
                  <p className="p-2">{originalText}</p>
                </Accordion>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EnhancedTextResult;
