import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


// Production config
const firebaseConfig = {
  apiKey: "AIzaSyBSGHfuYOu9V7agAIXkyPpuUT4SWr_ozkY",
  authDomain: "aiproofreader-prod.firebaseapp.com",
  projectId: "aiproofreader-prod",
  storageBucket: "aiproofreader-prod.appspot.com",
  messagingSenderId: "715123801869",
  appId: "1:715123801869:web:f857c85010ab1ad5d87a44",
  measurementId: "G-8XBDYTLDWQ"
};

// Development config
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);

// const analytics = getAnalytics(app);
