import React, { useState, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function UpdateProfile() {
  const newEmailRef = useRef();
  const emailConfirmRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, changeEmail, changePassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const handleEmailChange = async (e) => {
    e.preventDefault();
    if (newEmailRef.current.value !== emailConfirmRef.current.value) {
      return setError('Emails do not match.');
    }
    try {
      setError('');
      setMessage('');
      setIsLoading(true);
      await changeEmail(newEmailRef.current.value);
      setMessage('Email updated successfully.');
      setEditEmail(false);
    } catch {
      setError('Failed to update email.');
    }
    setIsLoading(false);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match.');
    }
    try {
      setError('');
      setMessage('');
      setIsLoading(true);
      await changePassword(passwordRef.current.value);
      setMessage('Password updated successfully.');
      setEditPassword(false);
    } catch {
      setError('Failed to update password.');
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden p-6 max-w-md mx-auto my-10">
      <h2 className="text-center text-2xl font-bold mb-4">Profile Settings</h2>
      <p className='p-2 bg-orange-300 text-orange-700 border border-orange-700 rounded-lg font-lg font-bold text-center'>THIS IS A PLACEHOLDER COMPONENT</p>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
      {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">{message}</div>}

      <div className="mb-4">
        <h4 className="mb-2 font-semibold">Change Email</h4>

        {editEmail ? (
          <form onSubmit={handleEmailChange}>
            <div className="mb-4">
              <input type="email" ref={newEmailRef} required className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="New email" />
            </div>
            <div className="mb-4">
              <input type="email" ref={emailConfirmRef} required className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Confirm new email" />
            </div>
            <div className="flex justify-between">
              <button disabled={isLoading} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed" type="submit">
                Submit
              </button>
              <button onClick={() => setEditEmail(false)} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700">
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className='flex items-center justify-between'>
            <p className=' align-baseline'>Signed in as: {currentUser.email}</p>
            <button onClick={() => setEditEmail(true)} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 mt-2">
              Edit Email
            </button>
          </div>
        )}
      </div>

      <div className="mb-4">
        <h4 className="mb-2 font-semibold">Change Password</h4>
        {editPassword ? (
          <form onSubmit={handlePasswordChange}>
            <div className="mb-4">
              <input type="password" ref={passwordRef} className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="New password" />
            </div>
            <div className="mb-4">
              <input type="password" ref={passwordConfirmRef} className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Confirm new password" />
            </div>
            <div className="flex justify-between">
              <button disabled={isLoading} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed" type="submit">
                Send confirmation email
              </button>
              <button onClick={() => setEditPassword(false)} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700">
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className='flex justify-between items-center'>
            <p className='align-baseline'>
              Click to change password
            </p>
            <button onClick={() => setEditPassword(true)} className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 mt-2">
              Reset Password
            </button>
          </div>
        )}
      </div>

      <div className="w-100 text-center mt-2">
        <Link to="/" className="text-blue-600 hover:text-blue-800"> Cancel</Link>
      </div>
    </div>
  );
}
