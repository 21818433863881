import React, { useState, useEffect, useRef } from "react";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { PasswordInput } from "../common/PasswordInput";

const ResetPassword = ({ auth, actionCode }) => {
  const [email, setEmail] = useState("test@mail.de");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);


  const newPasswordRef = useRef();
  const newPasswordConfirmRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setEmail(email);
      })
      .catch((error) => {
        setError(
          "Invalid or expired password reset code. Please try requesting another reset."
        );
      });
  }, [auth, actionCode]);

  const handlePasswordReset = (e) => {
    e.preventDefault();

    if (newPasswordRef.current.value !== newPasswordConfirmRef.current.value) {
      return setError("Passwords do not match.");
    }

    confirmPasswordReset(auth, actionCode, newPasswordRef.current.value)
      .then(() => {
        setMessage(
          "Your password has been changed successfully. You can now login with your new password."
        );
        setPasswordResetSuccess(true);
        // Optionally redirect the user or sign them in automatically here
      })
      .catch((error) => {
        setError(
          "Failed to reset password. The password might be too weak, or the reset code has expired."
        );
      });
  };

  const handleSignInHere = () => {
    navigate("/login");
  };

  return (
    <>
      <div
        className="w-full flex flex-col items-center justify-center h-screen z-50 pointer-events-auto"
        style={{ zIndex: 1000 }}
      >
        <div className=" bg-white dark:bg-gray-900 dark:text-gray-200 shadow-lg rounded-lg overflow-hidden w-full max-w-sm">
          <div className="p-6">
            <h1 className="text-center text-2xl font-bold mb-2">
              Reset QuackScribe Password
            </h1>
            {email && (
              <p className="text-center mb-8 standard-text">
                Reset password for{" "}
                <strong>{email}</strong>
              </p>
            )}

            <form onSubmit={handlePasswordReset}>
              <div className="mb-4">
                <PasswordInput ref={newPasswordRef} label="New Password" />
              </div>

              <div className="mb-4">
                <PasswordInput ref={newPasswordConfirmRef} label="Confirm New Password" />
              </div>
              {message && (
                <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                  {message}
                </div>
              )}
              {error && (
                <div
                  className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  {error}
                </div>
              )}
              {passwordResetSuccess ? (
                <>
                  <button
                    onClick={handleSignInHere}
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white dark:bg-green-600 bg-green-600 hover:bg-green-700 disabled:bg-green-300 disabled:cursor-not-allowed mt-4"
                  >
                    Sign in here!
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white dark:bg-blue-600 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed mt-4"
                >
                  Reset Password
                </button>
              )}

            </form>
          </div>
          <div className="w-full flex justify-center mb-2">
            <Link to="/" className="underline standard-text">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
