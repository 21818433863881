import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const EnhancedTextsContext = React.createContext();

export function useEnhancedTexts() {
  return useContext(EnhancedTextsContext);
}

export function EnhancedTextsProvider({ children }) {
  const [enhancedTexts, setEnhancedTexts] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, `users/${currentUser.uid}/enhancedTexts`),
        orderBy('started', 'desc')
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const texts = querySnapshot.docs.map(doc => {
          const data = doc.data();
          let dateString = "";
          if (data.started && typeof data.started.toDate === 'function') {
            dateString = data.started.toDate().toLocaleString();
          } else {
            dateString = "Timestamp unavailable";
          }

          return {
            id: doc.id,
            originalText: data.originalText,
            enhancedText: data.text,
            tokensUsed: data.totalTokensUsed,
            timestamp: dateString,
            isPending: data.status === 'pending'
          };
        });
        setEnhancedTexts(texts);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const deleteEnhancedText = async (id) => {
    if (!currentUser) return;
    await deleteDoc(doc(db, `users/${currentUser.uid}/enhancedTexts`, id));
  };

  const filterEnhancedTextsFromDate = (date) => {
    return enhancedTexts.filter(text => {
      const textDate = new Date(text.timestamp);
      return textDate >= date;
    });
  };

  const value = {
    enhancedTexts,
    deleteEnhancedText,
    filterEnhancedTextsFromDate
  };

  return (
    <EnhancedTextsContext.Provider value={value}>
      {children}
    </EnhancedTextsContext.Provider>
  );
}
