import React, { useState } from "react";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Navbar from "./common/Navbar";

// FAQItem Component
function FAQItem({ question, answer }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const animationStyle = {
    maxHeight: isAccordionOpen ? 500 : 0, // Adjust the maxHeight to fit your content
    opacity: isAccordionOpen ? 1 : 0,
    overflow: "hidden",
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  };
  return (
    <div className="border-b border-gray-300 py-6  standard-text">
      <div>
        <div
          onClick={toggleAccordion}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="font-semibold text-lg">{question}</span>
          <ChevronDownIcon
            className={`w-4 h-4 transition-transform ${isAccordionOpen ? "transform rotate-180" : ""
              }`}
          />
        </div>
      </div>
      <motion.div
        animate={animationStyle}
        className="mt-1 standard-text overflow-y-scroll overflow-x-hidden"
      >
        {answer}
      </motion.div>
    </div>
  );
}

// FAQPage Component
const FAQPage = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-start items-center dark:bg-gradient-to-b dark:from-gray-950 dark:to-gray-800">
      <Navbar />
      <div className="max-w-3xl px-2 overflow-y-auto">
        <h1 className="text-3xl font-bold mt-8 mb-4 standard-text">
          What are tokens?
        </h1>
        <p className="standard-text mb-2 max-w-md">
          Tokens are like points used to count how much text the AI can work on.
          Each enhancement request uses tokens. For example:
        </p>
        <p className="standard-text font-light">
          <span className=" bg-purple-300 dark:bg-purple-700">This </span>
          <span className=" bg-green-300 dark:bg-green-700">sentence </span>
          <span className=" bg-yellow-300 dark:bg-yellow-600">contains </span>
          <span className=" bg-red-300 dark:bg-red-700">six </span>
          <span className=" bg-blue-300 dark:bg-blue-800">tokens </span>
          <span className=" bg-purple-300 dark:bg-purple-700">!</span>
        </p>


        <div className="max-w-2xl mx-auto">
          <h2 className="text-2xl font-semibold mt-8 standard-text">
            Frequently Asked Questions (FAQ)
          </h2>
          <FAQItem
            question="How many tokens do i need?"
            answer={
              <>
                <p className="standard-text mb-2">
                  The longer your text, the more tokens it uses. Each text
                  enhancement shows an estimated token cost before AI works on
                  it. After the AI responds, you'll see the exact token cost.
                </p>
                <p>Check your token balance anytime in your user profile!</p>
              </>
            }
          />
          <FAQItem
            question="How do i get tokens?"
            answer={
              <>
                <p className="standard-text mb-2">
                  Your tokens are automatically topped up at the start of each
                  billing cycle. How many tokens you get depends on your
                  subscription plan.
                </p>
                <p>
                  You can also{" "}
                  <Link className="underline">purchase persistant tokens</Link>{" "}
                  at any time. (NOT YET IMPLEMENTED!)
                </p>
              </>
            }
          />
          <FAQItem
            question="What are tokens in the context of OpenAI API requests?"
            answer={
              <>
                <p className="standard-text mb-2">
                  For text enhancements, we use OpenAI's GPT-4 Turbo. Here,
                  tokens are parts of your text, like words or pieces of words.
                  They help in processing your request and figuring out the
                  cost.
                </p>
                <h3 className="font-semibold mt-2">So what's a token then?</h3>
                <p className="standard-text mb-1">
                  Think of tokens as pieces of a puzzle. Each piece can be a
                  whole word or just a part of it. The way the API breaks down
                  your text into these pieces is what we call tokenization.
                </p>
                <p className="standard-text mb-2">
                  For more details, check out this{" "}
                  <a
                    href="https://platform.openai.com/tokenizer"
                    className="standard-text mt-4 inline-block underline"
                  >
                    link to OpenAIs tokenizer!
                  </a>
                </p>
              </>
            }
          />

          <FAQItem
            question="Why does each request cost at least 200 tokens?"
            answer={
              <>
                <p className="standard-text mb-2">
                  Every request to the AI includes a 'system string' — a set of
                  instructions for the AI. This takes up 200 tokens. It's like
                  giving directions to ensure the AI understands and responds
                  the way you want.
                </p>
                <p>
                  <bold className="font-semibold">Pro tip:</bold> To get the
                  most out of your tokens, send longer requests! This way, the
                  system string takes up a smaller percentage of your tokens.
                </p>
              </>
            }
          />
        </div>

        <Link
          to="/"
          className="standard-text mt-8 block text-center underline mb-8"
        >
          Go back
        </Link>
      </div>
    </div>
  );
};

export default FAQPage;
