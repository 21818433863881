import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa"
import { PasswordInput } from "./common/PasswordInput";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth(); // signup function from AuthContext.js
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setIsLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch (e) {
      // if error is "FirebaseError: Firebase: Error (auth/invalid-credentials)." then set error to "Invalid credentials."
      if (e.code === "auth/invalid-credential") {
        setError("Email or password is incorrect.");
      } else {
        setError("Failed to log in to account. Sorry.");
      }
      console.log(e);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center h-screen z-50 pointer-events-auto" style={{ zIndex: 1000 }}>
        <div className=" bg-white dark:bg-gray-900 dark:text-gray-200 shadow-lg rounded-lg overflow-hidden w-full max-w-sm">
          <div className="p-6">
            <h1 className="text-center text-2xl font-bold mb-4">Login</h1>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block standard-text text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input type="email" id="email" ref={emailRef} required className="shadow standard-text dark:bg-gray-800 border dark:border-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" />
              </div>

              <div className="mb-4">

                <PasswordInput ref={passwordRef} labelText="Password" />
                <div className="w-full text-left">
                  <Link to="/forgot-password" className="text-blue-600 hover:text-blue-800 font-light text-sm"> Forgot Password? </Link>
                </div>
              </div>





              <button disabled={isLoading} className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white dark:bg-blue-600 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed mt-4" type="submit">
                Log In
              </button>
            </form>
            <div className="w-full text-center my-2">
              or
            </div>

            <button disabled={true} className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed" type="submit">
              <FaGoogle className="inline-block mr-2" />
              <span>Continue with Google (disabled)</span>
            </button>

            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}


            <div className="standard-text w-full text-center mt-3">
              <span>Need an account? </span>
              <Link to="/signup" className="text-blue-600 hover:text-blue-800">Sign up here!</Link>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
