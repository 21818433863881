import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { applyActionCode, checkActionCode } from "firebase/auth";
import { useAuth } from "../../../contexts/AuthContext";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

const VerifyEmail = ({ auth, actionCode }) => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState(
    "Your email has been successfully verified. You can now access your account.",
  );
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const { logout, currentUser, sendVerificationMail } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {

    if (currentUser && currentUser.emailVerified) {
      // Email is already verified, no need to check or apply action code
      setMessage("This email has already been verified!");
      // Optionally, you could also navigate the user away or perform some other action
      return; // Exit early to avoid running the verification logic
    }
    // Attempt to verify the user's email address with the action code
    checkActionCode(auth, actionCode)
      .then(() => {
        applyActionCode(auth, actionCode)
          .then(() => {
            setMessage(
              "Your email has been successfully verified. You can now access your account.",
            );
          })
          .catch((error) => {
            setError(
              "Failed to verify email. The verification link might be expired or invalid.",
            );
          });
      })
      .catch((error) => {
        setError(
          "Invalid or expired action code. Please request a new verification email.",
        );
      });
  }, [auth, actionCode, currentUser]);

  const handleReturnToLogin = () => {
    if (currentUser) {
      logout();
    }
    navigate("/login");
  };

  const handleResendVerificationEmail = async () => {
    await sendVerificationMail();
    setVerificationEmailSent(true);
  };

  if (currentUser && currentUser.emailVerified) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <div className="w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg dark:bg-gray-900 dark:text-gray-200">
          <div className="p-6">
            <CheckCircleIcon className="mx-auto mb-2 size-16 text-green-500" />
            <h1 className="mb-2 text-center text-2xl font-bold">You're good to go!</h1>
            <p className="standard-text text-center">
              {currentUser.email}
            </p>
            <p className="standard-text mb-8 text-center">
              has already been verified.
            </p>
            <div className="mt-4 flex w-full justify-center px-6 pb-0">
              <button
                onClick={handleReturnToLogin}
                className="mt-4 w-full rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 disabled:cursor-not-allowed disabled:bg-blue-300 dark:bg-blue-800 dark:hover:bg-blue-700"
              >
                Return to login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (currentUser && !currentUser.emailVerified) {
    return (
      <>
        <div className="flex h-screen w-full flex-col items-center justify-center">
          <div className="w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg dark:bg-gray-900 dark:text-gray-200">
            <div className="p-6">
              {error ? (
                <>
                  <XCircleIcon className="mx-auto mb-2 size-16 text-red-500" />
                  <h1 className="mb-2 text-center text-2xl font-bold">
                    Email verification failed
                  </h1>
                  <div
                    className="relative mt-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                    role="alert"
                  >
                    {error}
                  </div>
                  {verificationEmailSent ? (
                    <div
                      className="relative rounded border border-green-400 bg-green-100 px-4 py-2 text-green-700"
                      role="alert"
                    >
                      Verification email sent successfully.
                    </div>
                  ) : (
                    <div className="mt-5 text-center text-sm">
                      <button
                        type="button"
                        className="standard-text mb-2 me-2 rounded-lg bg-green-700 px-5 py-2 text-sm font-medium hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800"
                        onClick={handleResendVerificationEmail}
                      >
                        Request Verification Email
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <CheckCircleIcon className="mx-auto mb-2 size-16 text-green-500" />
                  <h1 className="mb-2 text-center text-2xl font-bold">
                    Your Email has been Verified!
                  </h1>
                  <p className="standard-text mb-8 text-center">{message}</p>
                  <div className="mt-4 flex w-full justify-center px-6 pb-0">
                    <button
                      onClick={handleReturnToLogin}
                      className="mt-4 w-full rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 disabled:cursor-not-allowed disabled:bg-blue-300 dark:bg-blue-800 dark:hover:bg-blue-700"
                    >
                      Return to login
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!currentUser) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <div className="w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg dark:bg-gray-900 dark:text-gray-200">
          <div className="p-6">
            <XCircleIcon className="mx-auto mb-2 size-16 text-red-500" />
            <h1 className="mb-2 text-center text-2xl font-bold">
              Email verification failed
            </h1>
            <div
              className="relative mt-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              You must be logged in to verify your email.
            </div>
            <div className="mt-4 flex w-full justify-center px-6 pb-0">
              <button
                onClick={handleReturnToLogin}
                className="mt-4 w-full rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 disabled:cursor-not-allowed disabled:bg-blue-300 dark:bg-blue-800 dark:hover:bg-blue-700"
              >
                Return to login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  else return (<></>);
};

export default VerifyEmail;
