import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import QuackscribeLogo from "./common/QuackscribeLogo";


function VerifyEmailCTA() {
  const { sendVerificationMail, logout, currentUser } = useAuth();
  const [verificationEmailSent, setVerificationEmailSent] =
    React.useState(false);
  const [resendButtonAvailable, setResendButtonAvailable] = useState(false);

  const [providerLink, setProviderLink] = useState('');

  const navigate = useNavigate();

  const handleResendVerificationEmail = async () => {
    await sendVerificationMail();
    setVerificationEmailSent(true);
  };

  useEffect(() => {
    console.log(currentUser);
    if (currentUser.emailVerified) {
      navigate("/dashboard");
    }
  }, [currentUser, logout, navigate]);

  useEffect(() => {
    if (verificationEmailSent) {
      setTimeout(() => {
        setVerificationEmailSent(false);
      }, 5000);
    }
  }, [verificationEmailSent]);

  useEffect(() => {
    setTimeout(() => {
      setResendButtonAvailable(true);
    }, 5000);
  }, [resendButtonAvailable]);

  // Gets the provider link with a filter for quackscribe.com, so that the user can see their verification email
  useEffect(() => {
    if (!currentUser) return;

    const email = currentUser.email;
    const emailProvider = email.split('@')[1]; // Gets the part of the email after '@'

    // Define links for the most common email providers
    const providerLinks = {
      'gmail.com': `https://mail.google.com/mail/u/0/#search/from%3A@quackscribe.com`,
      'yahoo.com': `https://mail.yahoo.com/d/search/messages?keyword=from%3A@quackscribe.com`,
      'outlook.com': `https://outlook.live.com/mail/search?q=from%3A@quackscribe.com`,
      'hotmail.com': `https://outlook.live.com/mail/search?q=from%3A@quackscribe.com`,
    };

    // Set the provider link based on the user's email provider
    const link = providerLinks[emailProvider] || '';
    setProviderLink(link);
  }, [currentUser]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="dark:border-2 dark:border-gray-800 dark:text-gray-200 shadow rounded-lg overflow-hidden w-full max-w-xl">
        <div className="p-12 px-16 flex flex-col gap-6">
          <QuackscribeLogo />
          <div className="standard-text flex flex-col gap-4">
            <h2 className="text-3xl font-bold">Let's verify your email!</h2>
            <div className="text-lg font-light">
              Check
              <span className="font-semibold"> {currentUser.email} </span>
              to verify your account and get started!
            </div>
          </div>
          <div className="flex w-full">
            {verificationEmailSent ? (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded relative"
                role="alert"
              >
                Verification email sent successfully.
              </div>
            ) : (
              <div className="flex gap-1">
                {providerLink && (
                  <a
                    href={providerLink}
                    className="standard-text bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={handleResendVerificationEmail}
                  >
                    Open Gmail
                  </a>
                )}
                <button
                  type="button"
                  disabled={!resendButtonAvailable}
                  className="standard-text bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 disabled:cursor-not-allowed disabled:opacity-50"
                  onClick={handleResendVerificationEmail}
                >
                  Resend Email
                </button>
              </div>
            )}
          </div>
          <div className="standard-text font-thin">
            <span>Need help?</span>
            <span className="text-blue-600 cursor-pointer"> Contact support</span>
          </div>


        </div>
      </div>
    </div >
  );
}

export default VerifyEmailCTA;
