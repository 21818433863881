import React from 'react'
import { useUserData } from '../../../contexts/UserDataContext';

function SubTierBadge() {
  const { userData } = useUserData();
  if (!userData) return (<></>)
  if (userData.subscriptionStatus === "free") {
    return <div className='bg-gray-500 text-white px-2 py-1 rounded-md'>Free</div>
  }
  else if (userData.subscriptionStatus === "basic") {
    return <div className='bg-emerald-500 text-white px-2 py-1 rounded-md'>Basic</div>
  }
  else if (userData.subscriptionStatus === "pro") {
    return <div className='bg-blue-500 text-white px-2 py-1 rounded-md'>Pro</div>
  }
  else {
    return <div className='bg-red-500 text-white px-2 py-1 rounded-full'>Error Loading Sub Tier</div>
  }
}

export default SubTierBadge