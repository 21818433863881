import { getFunctions, httpsCallable } from 'firebase/functions';

export const enhanceText = async (inputText) => {
  const functions = getFunctions(undefined, 'europe-west1');
  const enhanceTextFunction = httpsCallable(functions, 'enhanceText');

  try {
    let isPending = true;
    console.log('Input text:', inputText);
    const response = await enhanceTextFunction({ input: inputText });

    // Once the response is received, the request is no longer pending
    isPending = false;

    console.log('Enhanced text:', response.data.text);

    return {
      resultText: response.data.text,
      isPending
    };
  } catch (error) {
    console.error('Error enhancing text:', error);
    throw error;
  }
};
