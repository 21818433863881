import React, { useState, useEffect } from 'react';
import { auth } from '../../../firebase';
import ResetPassword from './ResetPassword';
import RecoverEmail from './RecoverEmail';
import VerifyEmail from './VerifyEmail';

const AccountManagement = () => {
  const [mode, setMode] = useState('');
  const [actionCode, setActionCode] = useState('');
  const [continueUrl, setContinueUrl] = useState('');
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setMode("verifyEmail");
    //setMode(urlParams.get('mode'));
    setActionCode(urlParams.get('oobCode'));
    setContinueUrl(urlParams.get('continueUrl'));
    setLang(urlParams.get('lang') || 'en');
  }, []);

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword actionCode={actionCode} continueUrl={continueUrl} lang={lang} auth={auth} />;
    case 'recoverEmail':
      return <RecoverEmail actionCode={actionCode} lang={lang} auth={auth} />;
    case 'verifyEmail':
      return <VerifyEmail actionCode={actionCode} continueUrl={continueUrl} lang={lang} auth={auth} />;
    default:
      // TODO: Render a 404 page
      return <div className='standard-text'>Invalid mode or action. Please check the link and try again.</div>;
  }
};

export default AccountManagement;
