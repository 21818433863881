import React, { useCallback, useEffect, useState } from "react";
import { encode } from "gpt-tokenizer";
import { useUserData } from "../../../contexts/UserDataContext";
import { SparklesIcon } from "@heroicons/react/24/outline";

function EnhancerSubmitForm({
  isEnhancing,
  handleEnhancerSubmit,
  inputText,
  setInputText,
}) {
  const systemStringTokens = 200; // in tokens
  const totalTokenLimit = 2048;
  const maximumOutputToInputRatio = 2;
  const { userData } = useUserData();
  const [warning, setWarning] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const countWordsInString = (text) => {
    return text ? text.split(/\s+/).filter(Boolean).length : 0;
  };

  const estimatedMaxTotalTokenCost = (text) => {
    const inputTokens = encode(text).length;
    return text
      ? Math.round(
        inputTokens +
        inputTokens * maximumOutputToInputRatio +
        systemStringTokens
      )
      : 0;
  };

  const handleSubmitButtonDisabled = useCallback(() => {
    const maxTotalTokens = estimatedMaxTotalTokenCost(inputText);
    if (inputText.length === 0) setSubmitDisabled(true);
    else if (maxTotalTokens >= totalTokenLimit) {
      setWarning("Input text exceeds maximum token limit (2048 tokens)");
      setSubmitDisabled(true);
    } else if (maxTotalTokens > userData.remainigAPITokens) {
      setWarning("Not enough API tokens");
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
      setWarning("");
    }
  }, [inputText, userData?.remainigAPITokens]);

  useEffect(() => {
    handleSubmitButtonDisabled();
  }, [inputText, handleSubmitButtonDisabled]);



  const handleInputTextChange = (e) => {
    setInputText(e.target.value);
  }

  return (
    <div className="w-full max-w-4xl px-4">
      {userData ? (
        <form onSubmit={handleEnhancerSubmit}>
          <div className="mt-4 flex border dark:border-none p-2 gap-2 dark:bg-gray-800 dark:text-gray-200 rounded-lg">
            <textarea
              className={`border-transparent focus:border-transparent focus:ring-0 resize-none border-none block w-full sm:text-sm dark:bg-gray-800 dark:text-gray-200 rounded-lg ${isEnhancing ? "cursor-not-allowed animate-pulse" : "cursor-text"
                }`}
              rows="5"
              disabled={isEnhancing}
              placeholder={
                isEnhancing
                  ? "Waiting for enhancement to finish..."
                  : "Enter a text to enhance..."
              }
              value={inputText}
              onChange={handleInputTextChange}
            />
            <div className="flex flex-col gap-2 justify-end dark:border-gray-600">
              <div className="standard-text mt-1 text-xs font-light">
                <span>Word Count: {countWordsInString(inputText)}</span>
                <p>Max Token cost: {estimatedMaxTotalTokenCost(inputText)}</p>
              </div>
              <div className="">
                {isEnhancing ? (
                  <button
                    className="inline-flex items-center px-3 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-gray-600 cursor-not-allowed animate-pulse"
                    disabled
                  >
                    <span className="ml-3">Enhancing...</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={submitDisabled}
                    className="inline-flex items-center px-3 py-2 border border-transparent font-medium rounded-md shadow-sm text-white disabled:bg-green-300 bg-green-600 hover:bg-green-700  dark:disabled:bg-gray-600 dark:bg-emerald-600 dark:hover:bg-emerald-700 disabled:cursor-not-allowed"
                  >
                    <SparklesIcon className="w-5 mr-2" />
                    <span className="">Enhance</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {warning && (
              <p className="text-orange-600 bg-orange-200 w-full max-w-sm text-start p-1 rounded-lg border border-orange-600 mt-2">
                {warning}
              </p>
            )}
          </div>
        </form>
      ) : (
        <div>User Data Loading...</div>
      )}
    </div>
  );
}

export default EnhancerSubmitForm;
