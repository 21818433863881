import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const UserContext = React.createContext();

export function useUserData() {
  return useContext(UserContext);
}

export function UserDataProvider({ children }) {
  const [userData, setUserData] = useState();
  const { currentUser } = useAuth();

  useEffect(() => {
    let unsubscribeUser;

    if (currentUser) {
      unsubscribeUser = onSnapshot(doc(db, "users", currentUser.uid),
        (doc) => {
          setUserData(doc.exists() ? doc.data() : null);
        },
        (error) => {
          console.error("Error getting user data:", error);
        }
      );
    } else {
      setUserData(null);
    }

    return unsubscribeUser;
  }, [currentUser]);

  const value = {
    userData,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}
