import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <body className=" standard-text flex flex-col h-screen justify-center items-center bg-gray-100 dark:bg-gray-950">
      <div className="flex flex-col items-center">
        <h1 className="text-[120px] font-extrabold">404</h1>
        <p class="text-2xl font-medium mb-6">Page Not Found</p>
        <p class="text-lg text-center mb-8 max-w-md font-light">The page you are looking for does not exist. How you got here is a mystery...</p>
        <p class="text-lg text-center mb-8 max-w-md font-light">But you can click the button below to go back to the homepage!</p>
        <Link to={'/'}
          class="px-4 py-2 font-medium text-white bg-indigo-500 rounded-md hover:bg-indigo-600 transition-all duration-200 ease-in-out">
          Go Home
        </Link>
      </div>
    </body>
  )
}

export default NotFound