import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { PasswordInput } from "./common/PasswordInput";


export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup, currentUser } = useAuth(); // signup function from AuthContext.js
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match.");
    }
    try {
      setError("");
      setIsLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate("/");
    } catch (e) {
      setError("Failed to create an account. Sorry.");
      console.log(e);
    }
    setIsLoading(false);
  }

  // if current user exists, redirect to dashboard
  useEffect(() => {
    if (currentUser && currentUser.emailVerified) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="bg-white dark:bg-gray-900 dark:text-gray-200 shadow-lg rounded-lg overflow-hidden w-full max-w-sm">
        <div className="p-6">
          <h2 className="text-center text-2xl font-bold mb-4">Welcome to QuackScribe! 🐣</h2>
          <h3 className="text-center text-lg font-bold mb-4">Create an account</h3>
          {/* <p className='p-2 bg-orange-300 text-orange-700 border border-orange-700 rounded-lg font-lg font-bold text-center'>ACCOUNT CREATION IS DISABLED IN BETA</p> */}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block standard-text text-sm font-bold mb-2" htmlFor="email">Email</label>
              <input type="email" id="email" ref={emailRef} required className="shadow standard-text dark:bg-gray-800 border dark:border-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" />
            </div>

            <div className="mb-4">
              <PasswordInput ref={passwordRef} labelText="Password" />
            </div>

            <div className="mb-4">
              <PasswordInput ref={passwordConfirmRef} labelText="Confirm Password" />
            </div>

            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

            <button disabled={isLoading} className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 dark:bg-blue-800 dark:hover:bg-blue-700 disabled:cursor-not-allowed mt-4" type="submit">
              Sign Up
            </button>
          </form>
        </div>
        <div className="standard-text w-full text-center mb-4">
          Already have an account?
          <Link to="/login" className="text-blue-600 hover:text-blue-800 dark:text-blue-600 dark:hover:text-blue-500 cursor-pointer"> Log In here! </Link>
        </div>
      </div>

    </div>
  );
}
