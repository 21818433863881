import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

function Accordion({ headerText, children }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const animationStyle = {
    maxHeight: isAccordionOpen ? 500 : 0, // Adjust the maxHeight to fit your content
    opacity: isAccordionOpen ? 1 : 0,
    overflow: 'hidden',
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  };

  return (
    <div>
      <div className={`flex justify-end p-2.5 bg-gray-200 dark:bg-gray-900 transition-transform duration-1000 ${isAccordionOpen ? '' : 'rounded-b-lg'}`}>
        <div onClick={toggleAccordion} className="flex gap-2 items-center cursor-pointer text-gray-600">
          <span className="standard-text-lc text-xs font-semibold">{isAccordionOpen ? 'Hide' : 'Show'} {headerText}</span>
          <ChevronDownIcon className={`w-4 h-4 transition-transform ${isAccordionOpen ? 'transform rotate-180' : ''}`} />
        </div>
      </div>

      <motion.div animate={animationStyle} style={{ maxHeight: 0 }} className="preformatted-text rounded-b-lg border-x border-b dark:border-2 dark:border-t-0 dark:border-gray-900 standard-text text-sm overflow-y-scroll overflow-x-hidden">
        {children}
      </motion.div>
    </div>
  );
}

export default Accordion;
