import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { checkActionCode, applyActionCode } from "firebase/auth";

const RecoverEmail = ({ auth, actionCode }) => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");


  useEffect(() => {
    // Verify the action code and determine the email being recovered
    checkActionCode(auth, actionCode)
      .then((info) => {
        // The API might return the email address of the user for some action types
        const restoredEmail = info.data?.email; // This depends on the Firebase version

        // Revert to the original email
        applyActionCode(auth, actionCode)
          .then(() => {
            setMessage(
              `Your email has been restored to ${restoredEmail}. You can now use your email to login.`
            );
            // Optional: Update the user's email in the profile, signIn the user
          })
          .catch((error) => {
            setError(
              "Failed to recover email. Please try again or contact support."
            );
          });
      })
      .catch((error) => {
        setError(
          "Invalid or expired action code. Please request a new email recovery."
        );
      });
  }, [auth, actionCode]);

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center h-screen">
        <div className="bg-white dark:bg-gray-900 dark:text-gray-200 shadow-lg rounded-lg overflow-hidden w-full max-w-sm">
          <div className="p-6">
            <h1 className="text-center text-2xl font-bold mb-2">
              Recover Email
            </h1>
            {message && (
              <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                {message}
              </div>
            )}
            {error && (
              <div
                className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                {error}
              </div>
            )}
            <div className="w-full flex justify-center mt-4">
              <Link
                to="/"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white dark:bg-blue-600 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed mt-4 text-center"
              >
                Return to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverEmail;
