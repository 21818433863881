import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetMailSent, setResetMailSent] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setIsLoading(true);
      await resetPassword(emailRef.current.value);
      setResetMailSent(true);
      console.log(resetMailSent);
      setMessage("Check your inbox for further instructions.");
    } catch (e) {
      setError("Failed to send reset email. Oh no.");
      console.log(e);
    }
    setIsLoading(false);
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="bg-white shadow rounded-lg overflow-hidden w-full max-w-md">
        <div className="p-6">
          <h2 className="text-center text-2xl font-bold mb-4">Password Reset</h2>
          {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
          {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
              <input type="email" id="email" ref={emailRef} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>

            <button
              disabled={isLoading || resetMailSent}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 dark:bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed mt-4"
              type="submit"
            >
              Send Reset Email
            </button>
          </form>
          <div className="w-full text-center mt-3">
            <Link to="/login" className="text-blue-600 hover:text-blue-800"> Login </Link>
          </div>
        </div>
      </div>
      <div className="standard-text w-full text-center mt-2">
        <span>Need an account? </span>
        <Link to="/signup" className="text-blue-600 hover:text-blue-800">Sign up here!</Link>
      </div>
    </div>

  );
}
