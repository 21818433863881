import React from 'react';
import SubTierBadge from './SubTierBadge';
import MiniProfile from './MiniProfile';
import { Link } from 'react-router-dom';

function Navbar() {

  return (

    <nav className="py-4 text-white z-50 w-full">
      <div className="container mx-auto flex justify-between items-center max-w-6xl px-4">
        <div className='flex gap-2 items-center'>

          <Link to={"/"} className="text-xl font-semibold standard-text">QuackScribe</Link>
          <SubTierBadge />
        </div>

        <MiniProfile />


      </div>
    </nav>
  );
}

export default Navbar;
