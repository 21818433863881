import React, { useState, forwardRef } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';

export const PasswordInput = forwardRef(({ labelText }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="">
      <label className="block text-sm font-bold mb-1 standard-text" htmlFor="password-input">
        {labelText}
      </label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          className="shadow standard-text dark:bg-gray-800 border dark:border-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="password-input"
          ref={ref}
          required
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <motion.div whileTap={{ scale: 0.9 }}>
            {showPassword ? (
              <EyeSlashIcon className="h-5 w-5 text-gray-700 cursor-pointer" onClick={togglePasswordVisibility} />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-700 cursor-pointer" onClick={togglePasswordVisibility} />
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';
