import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  signOut,
  sendEmailVerification,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function signup(email, password) {
    const newUserCredential = await createUserWithEmailAndPassword(auth, email, password);
    await sendVerificationMail(newUserCredential.user);
  }

  async function login(email, password) {
    return await signInWithEmailAndPassword(auth, email, password);
  }

  async function changeEmail(email) {
    return await updateEmail(auth.currentUser, email);
  }

  async function changePassword(password) {
    return await updatePassword(auth.currentUser, password);
  }

  async function resetPassword(email) {
    return await sendPasswordResetEmail(auth, email);
  }

  async function logout() {
    return await signOut(auth);
  }

  async function sendVerificationMail() {
    return await sendEmailVerification(auth.currentUser);
  }

  function checkIfUserIsVerified() {
    if (currentUser) {
      return currentUser.emailVerified;
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    resetPassword,
    changeEmail,
    changePassword,
    logout,
    sendVerificationMail,
    checkIfUserIsVerified,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}
